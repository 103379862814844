<template>
  <div class="step-progress">
    <div class="step-progress__bars">
      <div class="step-progress__bar uncompleted"></div>
      <div
        class="step-progress__bar completed"
        :style="'width:' + progressAmount + '%'"
      ></div>
    </div>
    <div class="step-progress__container">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="step-progress__step"
        :class="stateToClass[getState(index)]"
      >
        <img
          class="step-progress__step-icon"
          :src="getImageSrc(index)"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'

const state = {
  TODO: 0,
  IN_PROGRESS: 1,
  DONE: 2
}

export default {
  name: 'StepProgress',
  props: {
    // The steps that should be available.
    // The image should just be it's name; 'myImage.svg'
    // The path to the image is based on the iconFolderPath component.
    // Final property could look like this; steps="[['todo.svg', 'inprogess.svg', 'done.svg'], ['todo.svg', 'inprogess.svg', 'done.svg']]"
    steps: {
      type: Array,
      required: true
    },
    // The index of the current step. Starts from 0.
    currentStep: {
      type: Number,
      required: true
    },
    // The path to the directory of your images.
    // This is always in the assets folder.
    // WRONG: '@/assets/icons/myImage.svg'
    // GOOD: 'icons/myImage.svg'
    iconFolderPath: {
      type: String,
      required: false,
      default: 'icons/'
    }
  },
  setup(props) {
    const progressAmount = computed(() =>
      Math.round((props.currentStep / (props.steps.length - 1)) * 100)
    )

    const stateToClass = {
      [state.TODO]: 'uncompleted',
      [state.IN_PROGRESS]: 'in-progress',
      [state.DONE]: 'completed'
    }

    function getState(index) {
      if (index === props.currentStep) {
        return state.IN_PROGRESS
      } else if (index > props.currentStep) {
        return state.TODO
      } else if (index < props.currentStep) {
        return state.DONE
      }
    }

    function getImageSrc(index) {
      const result = props.steps[index][getState(index)]
      return require('@/assets/' + props.iconFolderPath + result)
    }

    return {
      progressAmount,
      stateToClass,

      getState,
      getImageSrc
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base.variables';
@import '~@/assets/css/base.mixins';

.step-progress {
  width: 50%;
  margin: auto;
  position: relative;
  margin-bottom: 2rem;
  .step-progress__bars {
    .step-progress__bar {
      position: absolute;
      width: 100%;
      margin: 0;
      height: rem(2);
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .step-progress__bar.completed {
      background: #ff006e;
    }

    .step-progress__bar.uncompleted {
      width: 100%;
      background: #d3d6e5;
    }
  }
  .step-progress__container {
    display: flex;
    justify-content: space-between;
    z-index: 2;

    .step-progress__step {
      z-index: inherit;
      border-radius: 50%;
      width: rem(28);
      height: rem(28);
    }
  }
}
</style>
